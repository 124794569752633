import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { RouteManager } from './components/RouteManager';

const queryClient = new QueryClient()

export const App = () => {

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <RouteManager />
      </BrowserRouter>
    </QueryClientProvider>
  )
}
